import api from "!../../node_modules/.pnpm/style-loader@2.0.0_webpack@5.94.0_webpack-cli@4.10.0_/node_modules/style-loader/dist/runtime/injectStylesIntoStyleTag.js";
            import content from "!!../../node_modules/.pnpm/mini-css-extract-plugin@1.6.2_webpack@5.94.0_webpack-cli@4.10.0_/node_modules/mini-css-extract-plugin/dist/loader.js??ruleSet[1].rules[1].use[1]!../../node_modules/.pnpm/css-loader@7.1.2_webpack@5.94.0_webpack-cli@4.10.0_/node_modules/css-loader/dist/cjs.js!../../node_modules/.pnpm/sass-loader@14.2.1_node-sass@9.0.0_webpack@5.94.0_webpack-cli@4.10.0_/node_modules/sass-loader/dist/cjs.js!./overlay.scss";

var options = {};

options.insert = "head";
options.singleton = false;

var update = api(content, options);



export default content.locals || {};